//mixins
@mixin center-flex($direction: row,$main: center, $cross: center) {
    display: flex;
    flex-direction: $direction;
    justify-content: $main;
    align-items: $cross;
}
@mixin allSpace() {
    width:100%;
    height: 100%;
}

//variables
$lightColor: #f3f3f4;
$darkColor: #12130f;
$firstColor: #C8871F;

$darkColorOpacity: #12130fb1;

$picSize: 35vw;
$respSize: 40vw;
//general
html{
    scroll-behavior: smooth;
}
a{
    text-decoration: none;
    color: black;
}

//main styling
section{
    height: 100%;
}
//navbar
.nav-container {
    position: fixed;
    width: 100%;
    z-index: 10;
    @include center-flex(row, flex-start, center);
    padding-left: 40px;
    background-color: $darkColor;
    // box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 3px );
}
.nav-list {
    padding: 12px 0;
    @include center-flex(row, center,center);
    gap: 48px;
    a {
        color: $firstColor;
    }
}

//portada
.sect-portada {
    background-color: $darkColor;
    color: $lightColor;
    @include center-flex(row, space-between, flex-start);
    padding: 0 20px;
}
.info-portada{
    @include center-flex(column, flex-start , flex-start);
    
    p {
    font-size: 150%;
    margin: 10px 0px;
   }
}
h1{
    font-size: 420%;
    background-color: $darkColorOpacity;
}
.btn-portada {
    font-size: 150%;
    color: $lightColor;
    width: fit-content;
    height: fit-content;
    background-color: $firstColor;
    border-radius: 5px;
    margin-top: 100px;
    padding: 12px;
    box-shadow: 10px 10px 35px -15px $firstColor;
}
.slider{
    margin-top: 60px;
    width: 45%;
    height:auto;
}
.slider-img{
    aspect-ratio: 4/3;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.slider-img-icon{
    width: 100%;
    aspect-ratio: 4/3;
}
.slick-dots {
    bottom: auto !important;
    li {
        width: 60px !important;
        height: 60px !important;
    }
}
//indumentaria
.sect-ind {
    background-image: url('../assets/images/ind-bkg.webp');
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;
}
.ind-content {
    min-height: fit-content;
    display: flex;
    flex-flow: column;
    margin:0 40px;
    width: 95%;
    h2{
        color: $firstColor;
        font-size: 250%;
        margin:0;
    }
 }

.fichero {
    z-index: 5;
    @include center-flex(row, flex-start, center);
    color: $lightColor;
    margin:15px 0 10px 0;
    overflow: hidden;
    // gap: 12px;
}
.slider-cats{
    display: flex;
    width: 90%;
    height: auto;
    overflow: hidden;
}
.arrow{
    display: none;
    color: $lightColor;
    font-size: 150%;
    z-index: 5;
    cursor: pointer;
}
.categorias-container {
    width: 100%;
    overflow: hidden;
    display: flex;
}
.categorias {
    @include center-flex();
    gap: 32px;
    font-size: 1.2rem;
    transition: all 1s;
    transform: translateX(0);
}
.indCat {
    display: flex !important;
    p{
        padding-right: 32px;
    }    
}
.indCat-btn{
    // padding: 0 32px;
    cursor: pointer;
    transition: all 0.7s;
}
.activeCat {
    color: $firstColor;
}
.ficha {
    width: 100%;
    gap: 32px;
    margin:15px 0 50px 0;
    flex: 1 1 auto;
    display: flex;
}
.prim-part{
    @include center-flex(column, flex-start, flex-start);
    width: 65%;
    img {
        // height: 594px;
        height: $picSize;
        aspect-ratio: 3/2;
        object-fit: cover;
        box-shadow: 10px 10px 50px -20px rgba(214,214,214,0.75);
    }
}
.seg-part{
    @include center-flex(column, flex-start, flex-start);
    width: 50%;
    height: $picSize;
    display: flex;
    gap: 32px;
    img {
        height: calc(50% - 16px);
        // height: calc(35vw - 16px);
        aspect-ratio: 3/2;
        object-fit: cover;
        box-shadow: 10px 10px 50px -20px rgba(214,214,214,0.75);
    }
}
//contacto
.sect-contacto {
    background-color: $lightColor;
    @include center-flex();
}
.contact-content {
    @include center-flex(row, space-between, flex-start);
    padding: 0 40px;
    flex: 1;
    color: $darkColor;
    h2{
        margin-top: 0;
        font-size: 250%;
    }
}
.info-contacto {
    @include center-flex(column, flex-start,flex-start);
}
.detalle-contacto {
    @include center-flex(column, flex-start,flex-start);
    width: 100%;
    flex-wrap: wrap;
}
.contact-icon{
    vertical-align: sub;
}
.mapa-contacto{
    width: 60%;
    height: auto;
    box-shadow: 10px 10px 40px 0px rgba(0,0,0,0.7);
}
.medios-de-pago{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.medioIcon {
    width: 85px;
    height: auto;
    vertical-align: middle;
}
//footer
footer {
    background-color: $lightColor;
    color: $darkColor;
    @include center-flex(row, space-between, center);
    padding: 0px 6px;
    p {
        margin: 0;
        a {
            color: $darkColor;
            text-decoration: underline;
        }
    }
}
.footer-icons a{
    font-size: 150%;
    color: $darkColor;
    > * {
        padding-right: 5px;
    }
}

//MEDIA QUERYS
@media screen and (max-width: 1600px) {
.prim-part{
    img{
        height: $respSize;
    }
}
.seg-part{
    height: $respSize;
}
}
@media screen and (max-width: 1200px) {
    //portada
    .btn-portada{
        margin-top: 50px;
    }
    h1{
        margin-bottom: 10px;
    }
    // indumentaria
    .sect-ind{
        background-image: url('../assets/images/responsive-ind-bkg.webp');
    }
    .ind-content{
        margin: 0;
        width: 100%;
        h2{
            width: 90%;
            align-self: center;
        }
    }
    .fichero{
        width: 90%;
        align-self: center;
    }
    .ficha{
        align-self: center;
        width: 90%;
        flex-direction: column;
        gap: 0;
        padding-bottom: 10vh;
        height: auto;
    }
    .prim-part{
        width: 100%;
        img{
            height: auto;
            width: 100%;
        }
    }
    .seg-part{
        flex-direction: row;
        gap: 0;
        width: 100%;
        height: auto;
        img{
            height: auto;
            width: 50%;
        }
    }
    //contacto
    .mapa-contacto{
        align-self: center;
    }
}

@media screen and ((max-width: 900px) or (orientation: portrait)) {
    //nav
    .nav-container {
        bottom: 0;
        justify-content: center;
        padding-left: 0;
    }
    .nav-list {
        padding: 15px 0;
    }
    // portada
    .sect-portada{
        flex-direction: column;
        justify-content: flex-start;
    }
    .slider{
        width: 80%;
        align-self: center;
        margin-bottom: 90px;
    }
    .info-portada {
        p{
            font-size: 120%;
        }
    }
    .btn-portada{
        margin-top: 40px;
    }
    .img-portada{
        width: 95%;
        margin: 10% 0  ;
    }
    h1{
        font-size: 2.4em;
    }
    //indumentaria
    .ficha{
        width: 100%;
    }
    .seg-part{
        flex-direction: row;
    }
    // contacto
    .sect-contacto{
        flex-direction: column;
        h2{
            margin-top: 24px;
        }
    }
    .info-contacto{
        width: 100%;
    }
    .contact-content{
        flex-direction: column;
        justify-content: flex-start;
        h2{
            margin-bottom: 10px;
        }
    }
    .horarios{
        width: 100% !important;
    }
    .mapa-contacto{
        width: 100%;
        margin-bottom: 48px;
        margin-top: 5%;
    }
    .detalle-contacto{
        width: 100%;
        flex-direction: row;
        p{
            margin: 10px 0;
            width: 50%;
            display: inline-block;
        }
    }
    .medios-de-pago{
        width: 100%;
        justify-content: center;
    }
}
@media screen and (max-width: 600px){
    .seg-part{
        flex-direction: column;
        height: auto;
        img{
            width: 100%;
        }
    }
}